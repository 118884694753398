import URI from 'urijs';
import { Iri } from '../entity';
import ProviderExportedCart, {
  PROVIDER_EXPORTED_CART,
} from '../entity/ProviderExportedCart';
import { assertRelationIsDefined } from '../errors/errorFactory';
import { TSMetadata } from '../mapping';
import getFieldsString, { ShortId } from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class ProviderExportedCartClient extends AbstractClient<
  TSMetadata['providerExportedCart']
> {
  getPathBase(): string {
    return '/v1/provider_exported_carts';
  }

  publish(
    contract: Iri<'Contract'>,
    providerName: PROVIDER_EXPORTED_CART,
    cart: Iri<'Cart'>,
    data: Record<string, unknown>,
    fields: FieldsParameter
  ): Promise<ProviderExportedCart> {
    const url = new URI(this.getPathBase());
    url.addSearch({ fields: getFieldsString(fields) });

    const body = {
      contract,
      providerName,
      cart,
      data,
    };

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
      'item'
    );
  }

  republish(
    contract: Iri<'Contract'>,
    providerName: PROVIDER_EXPORTED_CART,
    cart: Iri<'Cart'>,
    data: Record<string, unknown>,
    fields: FieldsParameter,
    id: ShortId | undefined
  ): Promise<ProviderExportedCart> {
    assertRelationIsDefined(id);
    const url = new URI(`${this.getPathBase()}/${id}`);
    url.addSearch({ fields: getFieldsString(fields) });

    const body = {
      contract,
      providerName,
      cart,
      data,
    };

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
      }),
      'item'
    );
  }
}

export default ProviderExportedCartClient;
