import { Map } from 'immutable';
import { Mapping, MetadataDefinition } from 'rest-client-sdk';
import AuditDocument from './entity/AuditDocument';
import AvailableSeat, {
  AvailableSeatNonNullProps,
} from './entity/AvailableSeat';
import AvailableSeatModel from './entity/AvailableSeatModel';
import BankAccount from './entity/BankAccount';
import Billing from './entity/Billing';
import Booking from './entity/Booking';
import BookingItem from './entity/BookingItem';
import BookingTrack from './entity/BookingTrack';
import BookingTrackMode from './entity/BookingTrackMode';
import Cart from './entity/Cart';
import CartItem from './entity/CartItem';
import Checkpoint from './entity/Checkpoint';
import Client from './entity/Client';
import Contingent from './entity/Contingent';
import Contract, { ContractNonNullProps } from './entity/Contract';
import ContractAddendum from './entity/ContractAddendum';
import Coupon from './entity/Coupon';
import Customer from './entity/Customer';
import CustomerField from './entity/CustomerField';
import CustomerFieldContract from './entity/CustomerFieldContract';
import CustomerStatus from './entity/CustomerStatus';
import Deposit from './entity/Deposit';
import DeskPaymentMethod, {
  DeskPaymentMethodNonNullProps,
} from './entity/DeskPaymentMethod';
import DeskSession from './entity/DeskSession';
import DeviceState from './entity/DeviceState';
import EventDate, { EventDateNonNullProps } from './entity/EventDate';
import EventDatePattern from './entity/EventDatePattern';
import Feature from './entity/Feature';
import Fee from './entity/Fee';
import Form from './entity/Form';
import Invoice from './entity/Invoice';
import InvoiceLine from './entity/InvoiceLine';
import Job from './entity/Job';
import Lettering from './entity/Lettering';
import LogicalSeatConfig from './entity/LogicalSeatConfig';
import MassActionAggregate from './entity/MassActionAggregate';
import MasterTicket from './entity/MasterTicket';
import Minisite from './entity/Minisite';
import Module from './entity/Module';
import ProviderTicketImportedFile from './entity/Module/Import/ProviderTicketImportedFile';
import ProviderAutoImportedTicket from './entity/Module/ProviderTicketMapping/ProviderAutoImportedTicket';
import ProviderContingentMapping from './entity/Module/ProviderTicketMapping/ProviderContingentMapping';
import ProviderMappingNotification from './entity/Module/ProviderTicketMapping/ProviderMappingNotification';
import ProviderSeatConfigMapping from './entity/Module/ProviderTicketMapping/ProviderSeatConfigMapping';
import ProviderSeatMapping from './entity/Module/ProviderTicketMapping/ProviderSeatMapping';
import ProviderTicketPriceMapping from './entity/Module/ProviderTicketMapping/ProviderTicketPriceMapping';
import ProviderTicketingMapping from './entity/Module/ProviderTicketMapping/ProviderTicketingMapping';
import SibilParameter from './entity/Module/Sibil/SibilParameter';
import SibilTicketingSpectacle from './entity/Module/Sibil/SibilTicketingSpectacle';
import SibilVenueLieu from './entity/Module/Sibil/SibilVenueLieu';
import Notification from './entity/Notification';
import OfferRule from './entity/OfferRule';
import Order from './entity/Order';
import OrderFeeItem from './entity/OrderFeeItem';
import OrderItem from './entity/OrderItem';
import OrderItemCashCoupon from './entity/OrderItemCashCoupon';
import OrderItemInvoiceGroup from './entity/OrderItemInvoiceGroup';
import OrganizationalUnit from './entity/OrganizationalUnit';
import PagedCartCollection from './entity/PagedCartCollection';
import PagedCollection from './entity/PagedCollection';
import Payment from './entity/Payment';
import PaymentAttempt from './entity/PaymentAttempt';
import PaymentFee from './entity/PaymentFee';
import Payout from './entity/Payout';
import ProviderExportedCart from './entity/ProviderExportedCart';
import ProviderTicketCredentials from './entity/ProviderTicketCredentials';
import Refund from './entity/Refund';
import RefundOrderItem from './entity/RefundOrderItem';
import Scan from './entity/Scan';
import ScanConfig from './entity/ScanConfig';
import ScanTrack from './entity/ScanTrack';
import ScanTrackCheckpoint from './entity/ScanTrackCheckpoint';
import Season, { SeasonNonNullProps } from './entity/Season';
import Seat from './entity/Seat';
import SeatConfig, { SeatConfigNonNullProps } from './entity/SeatConfig';
import SeatConfigBlock from './entity/SeatConfigBlock';
import SeatGroup from './entity/SeatGroup';
import SellingDevice from './entity/SellingDevice';
import Shipping from './entity/Shipping';
import Sticker from './entity/Sticker';
import StockContingent from './entity/StockContingent';
import Subscription from './entity/Subscription';
import SubscriptionMaturity from './entity/SubscriptionMaturity';
import Tag from './entity/Tag';
import Tax from './entity/Tax';
import Ticket from './entity/Ticket';
import TicketCheckpoint from './entity/TicketCheckpoint';
import TicketPrice, { TicketPriceNonNullProps } from './entity/TicketPrice';
import TicketPriceGroup from './entity/TicketPriceGroup';
import TicketPriceSeatGroup from './entity/TicketPriceSeatGroup';
import Ticketing, { TicketingNonNullProps } from './entity/Ticketing';
import TicketingCategory, {
  TicketingCategoryNonNullProps,
} from './entity/TicketingCategory';
import TicketingOptionStress from './entity/TicketingOptionStress';
import TicketingSellingDevice from './entity/TicketingSellingDevice';
import Transaction from './entity/Transaction';
import TransactionGroup from './entity/TransactionGroup';
import User from './entity/User';
import Venue, { VenueNonNullProps } from './entity/Venue';
import Version from './entity/Version';
import Wallet from './entity/Wallet';

const mapping = new Mapping('/v1');

const entityList = [
  AuditDocument,
  AvailableSeat,
  AvailableSeatModel,
  BankAccount,
  Booking,
  BookingItem,
  BookingTrack,
  BookingTrackMode,
  Cart,
  CartItem,
  Checkpoint,
  Client,
  Contingent,
  Contract,
  ContractAddendum,
  Coupon,
  Customer,
  CustomerFieldContract,
  CustomerField,
  CustomerStatus,
  DeskPaymentMethod,
  DeskSession,
  Deposit,
  DeviceState,
  EventDate,
  EventDatePattern,
  Feature,
  Fee,
  Form,
  Invoice,
  InvoiceLine,
  Job,
  Lettering,
  LogicalSeatConfig,
  MassActionAggregate,
  MasterTicket,
  Minisite,
  Module,
  Notification,
  OfferRule,
  Order,
  OrderFeeItem,
  OrderItem,
  OrderItemInvoiceGroup,
  OrderItemCashCoupon,
  OrganizationalUnit,
  Payment,
  PaymentAttempt,
  PaymentFee,
  Transaction,
  TransactionGroup,
  Payout,
  ProviderExportedCart,
  ProviderTicketCredentials,
  ProviderTicketImportedFile,
  ProviderContingentMapping,
  ProviderMappingNotification,
  ProviderTicketingMapping,
  ProviderTicketPriceMapping,
  ProviderSeatMapping,
  ProviderSeatConfigMapping,
  ProviderAutoImportedTicket,
  Refund,
  RefundOrderItem,
  Scan,
  ScanConfig,
  ScanTrack,
  ScanTrackCheckpoint,
  Season,
  Seat,
  SeatConfig,
  SeatConfigBlock,
  SeatGroup,
  SellingDevice,
  Shipping,
  Billing,
  SibilParameter,
  SibilTicketingSpectacle,
  SibilVenueLieu,
  StockContingent,
  Sticker,
  Subscription,
  SubscriptionMaturity,
  Tag,
  Tax,
  Ticket,
  TicketCheckpoint,
  TicketPrice,
  TicketPriceGroup,
  Ticketing,
  TicketingCategory,
  TicketingOptionStress,
  TicketingSellingDevice,
  TicketPriceSeatGroup,
  User,
  Venue,
  Version,
  Wallet,
];

entityList.forEach((entity) => {
  if (!entity.classMetadata) {
    // eslint-disable-next-line no-console
    console.warn(`No metadata found for entity named "${entity.name}"`);
  }
});

const classMetadataList = entityList.map((entity) => entity.classMetadata);

mapping.setMapping(classMetadataList);

export default mapping;

export type ObjectEntity = Record<string, unknown>;

export interface EntityMetadata<
  T extends ObjectEntity,
  MF extends string = '@id',
  LT extends PagedCollection<T> = PagedCollection<T>
> extends MetadataDefinition {
  entity: T;
  nonNullFields: MF;
  list: LT;
}

export type TSMetadata = {
  sibilParameter: EntityMetadata<SibilParameter>;
  sibilTicketingSpectacle: EntityMetadata<SibilTicketingSpectacle>;
  sibilVenueLieu: EntityMetadata<SibilVenueLieu>;
  sibilProxy: EntityMetadata<Map<string, unknown>>;
  auditDocument: EntityMetadata<AuditDocument>;
  availableSeat: EntityMetadata<AvailableSeat, AvailableSeatNonNullProps>;
  availableSeatModel: EntityMetadata<AvailableSeatModel>;
  bankAccount: EntityMetadata<BankAccount>;
  booking: EntityMetadata<Booking>;
  bookingItem: EntityMetadata<BookingItem>;
  bookingTrack: EntityMetadata<BookingTrack>;
  bookingTrackMode: EntityMetadata<BookingTrackMode>;
  cart: EntityMetadata<Cart, '@id', PagedCartCollection>;
  cartItem: EntityMetadata<CartItem>;
  checkpoint: EntityMetadata<Checkpoint>;
  client: EntityMetadata<Client>;
  contingent: EntityMetadata<Contingent>;
  contract: EntityMetadata<Contract, ContractNonNullProps>;
  contractAddendum: EntityMetadata<ContractAddendum>;
  coupon: EntityMetadata<Coupon>;
  customer: EntityMetadata<Customer>;
  customerField: EntityMetadata<CustomerField>;
  customerFieldContract: EntityMetadata<CustomerFieldContract>;
  customerStatus: EntityMetadata<CustomerStatus>;
  deposit: EntityMetadata<Deposit>;
  deskPaymentMethod: EntityMetadata<
    DeskPaymentMethod,
    DeskPaymentMethodNonNullProps
  >;
  deskSession: EntityMetadata<DeskSession>;
  deviceState: EntityMetadata<DeviceState>;
  eventDate: EntityMetadata<EventDate, EventDateNonNullProps>;
  eventDatePattern: EntityMetadata<EventDatePattern>;
  feature: EntityMetadata<Feature>;
  fee: EntityMetadata<Fee>;
  form: EntityMetadata<Form>;
  invoice: EntityMetadata<Invoice>;
  invoiceLine: EntityMetadata<InvoiceLine>;
  job: EntityMetadata<Job>;
  lettering: EntityMetadata<Lettering>;
  logicalSeatConfig: EntityMetadata<LogicalSeatConfig>;
  massActionAggregate: EntityMetadata<MassActionAggregate>;
  providerExportedCart: EntityMetadata<ProviderExportedCart>;
  ProviderSeatMapping: EntityMetadata<ProviderSeatMapping>;
  ProviderSeatConfigMapping: EntityMetadata<ProviderSeatConfigMapping>;
  providerContingentMapping: EntityMetadata<ProviderContingentMapping>;
  providerTicketPriceMapping: EntityMetadata<ProviderTicketPriceMapping>;
  providerTicketingMapping: EntityMetadata<ProviderTicketingMapping>;
  providerMappingNotification: EntityMetadata<ProviderMappingNotification>;
  providerAutoImportedTicket: EntityMetadata<ProviderAutoImportedTicket>;
  masterTicket: EntityMetadata<MasterTicket>;
  minisite: EntityMetadata<Minisite>;
  module: EntityMetadata<Module>;
  notification: EntityMetadata<Notification>;
  offerRule: EntityMetadata<OfferRule>;
  order: EntityMetadata<Order>;
  orderFeeItem: EntityMetadata<OrderFeeItem>;
  orderItem: EntityMetadata<OrderItem>;
  orderItemInvoiceGroup: EntityMetadata<OrderItemInvoiceGroup>;
  orderItemCashCoupon: EntityMetadata<OrderItemCashCoupon>;
  organizationalUnit: EntityMetadata<OrganizationalUnit>;
  payment: EntityMetadata<Payment>;
  paymentAttempt: EntityMetadata<PaymentAttempt>;
  paymentFee: EntityMetadata<PaymentFee>;
  payout: EntityMetadata<Payout>;
  providerTicketCredentials: EntityMetadata<ProviderTicketCredentials>;
  providerTicketImportedFile: EntityMetadata<ProviderTicketImportedFile>;
  refund: EntityMetadata<Refund>;
  refundOrderItem: EntityMetadata<RefundOrderItem>;
  scan: EntityMetadata<Scan>;
  scanTrack: EntityMetadata<ScanTrack>;
  scanTrackCheckpoint: EntityMetadata<ScanTrackCheckpoint>;
  scanConfig: EntityMetadata<ScanConfig>;
  season: EntityMetadata<Season, SeasonNonNullProps>;
  seat: EntityMetadata<Seat>;
  seatConfig: EntityMetadata<SeatConfig, SeatConfigNonNullProps>;
  seatConfigBlock: EntityMetadata<SeatConfigBlock>;
  seatGroup: EntityMetadata<SeatGroup>;
  sellingDevice: EntityMetadata<SellingDevice>;
  stockContingent: EntityMetadata<StockContingent>;
  shipping: EntityMetadata<Shipping>;
  billing: EntityMetadata<Billing>;
  sticker: EntityMetadata<Sticker>;
  subscription: EntityMetadata<Subscription>;
  subscriptionMaturity: EntityMetadata<SubscriptionMaturity>;
  tag: EntityMetadata<Tag>;
  ticket: EntityMetadata<Ticket>;
  ticketCheckpoint: EntityMetadata<TicketCheckpoint>;
  ticketing: EntityMetadata<Ticketing, TicketingNonNullProps>;
  ticketingCategory: EntityMetadata<
    TicketingCategory,
    TicketingCategoryNonNullProps
  >;
  ticketingOptionStress: EntityMetadata<TicketingOptionStress>;
  ticketingSellingDevice: EntityMetadata<TicketingSellingDevice>;
  ticketPrice: EntityMetadata<TicketPrice, TicketPriceNonNullProps>;
  ticketPriceGroup: EntityMetadata<TicketPriceGroup>;
  ticketPriceSeatGroup: EntityMetadata<TicketPriceSeatGroup>;
  transaction: EntityMetadata<Transaction>;
  transactionGroup: EntityMetadata<TransactionGroup>;
  tax: EntityMetadata<Tax>;
  user: EntityMetadata<User>;
  venue: EntityMetadata<Venue, VenueNonNullProps>;
  version: EntityMetadata<Version>;
  wallet: EntityMetadata<Wallet>;
};
