import clone from 'clone';
import { List, Map, MapOf } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import CustomerClient from '../client/CustomerClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import {
  CUSTOMER_TYPE,
  GdprInformationType,
  ShoppingDataType,
  DiffusionListType,
  ShippingDataType,
} from '../types/CustomerTypes';
import { parseDate } from '../utils/date';
import Client from './Client';
import Contract from './Contract';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import { extractTimezone } from './utils';
import { BaseEntity, PartialEntity } from '.';

export type CustomerType = BaseEntity<'Customer'> & {
  crmId: null | string;
  type: null | CUSTOMER_TYPE;
  lastname: null | string;
  firstname: null | string;
  email: null | string;
  birthday: null | string;
  nationality: null | string;
  countryOfResidence: null | string;
  civility: null | string;
  zipcode: null | string;
  address: null | string;
  city: null | string;
  phone: null | string;
  additionnalInformation: null | Map<string, unknown>;
  shippingData?: null | MapOf<ShippingDataType>;
  shoppingData: null | MapOf<ShoppingDataType>;
  diffusionList: null | MapOf<DiffusionListType>;
  gdprInformation: null | GdprInformationType;
  createdAt: null | Moment;
  orderList: null | List<Order>;
  client: null | Client;
  contract: null | Contract;
};

class Customer extends NetworkEntity<CustomerType>({
  '@id': null,
  '@type': 'Customer',
  type: null,
  crmId: null,
  lastname: null,
  firstname: null,
  email: null,
  birthday: null,
  nationality: null,
  countryOfResidence: null,
  civility: null,
  zipcode: null,
  address: null,
  city: null,
  phone: null,
  additionnalInformation: null,
  diffusionList: null,
  shippingData: undefined,
  shoppingData: null,
  gdprInformation: null,
  createdAt: null,
  orderList: List<Order>(),
  client: null,
  contract: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<CustomerType> = { '@id': null, '@type': 'Customer' }
  ) {
    const data = clone(val);

    data.createdAt = parseDate(
      data.createdAt,
      extractTimezone(data, ['contract', 'timezone'])
    );

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/customers/', '') || '';
  }
}

Customer.classMetadata = new ClassMetadata(
  'customer',
  'customers',
  /** @ts-expect-error -- method signature are incompatible */
  CustomerClient
);
Customer.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('crmId'),
  new Attribute('lastname'),
  new Attribute('firstname'),
  new Attribute('email'),
  new Attribute('birthday'),
  new Attribute('nationality'),
  new Attribute('countryOfResidence'),
  new Attribute('civility'),
  new Attribute('zipcode'),
  new Attribute('address'),
  new Attribute('city'),
  new Attribute('phone'),
  new Attribute('additionnalInformation', 'additionnalInformation', 'object'),
  new Attribute('shippingData', 'shippingData', 'object'),
  new Attribute('shoppingData', 'shoppingData', 'object'),
  new Attribute('diffusionList', 'diffusionList', 'object'),
  new Attribute('gdprInformation', 'gdprInformation', 'object'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
]);
Customer.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'order', 'orderList'),
  new Relation(Relation.MANY_TO_ONE, 'client', 'client'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
]);

export default Customer;
