import { List } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderTicketingMappingClient from '../../../client/ProviderTicketingMappingClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import Ticketing from '../../Ticketing';
import { ProviderTicketMappingType } from './ProviderTicketMappingType';
import ProviderTicketPriceMapping from './ProviderTicketPriceMapping';

export type ProviderTicketingMappingType = BaseEntity<'ProviderTicketingMapping'> &
  ProviderTicketMappingType & {
    ticketing?: EntityRelation<Ticketing> | null;
    providerTicketPriceMappingList?: List<
      EntityRelation<ProviderTicketPriceMapping>
    >;
  };

class ProviderTicketingMapping extends NetworkEntity<ProviderTicketingMappingType>(
  {
    '@id': null,
    '@type': 'ProviderTicketingMapping',
    contract: undefined,
    provider: undefined,
    ticketing: undefined,
    label: undefined,
    isMapped: undefined,
    providerTicketPriceMappingList: undefined,
    isIgnored: undefined,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderTicketingMappingType> = {
      '@id': null,
      '@type': 'ProviderTicketingMapping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_ticketing_mappings/', '') || ''
    );
  }
}

ProviderTicketingMapping.classMetadata = new ClassMetadata(
  'providerTicketingMapping',
  'provider_ticketing_mappings',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderTicketingMappingClient
);
ProviderTicketingMapping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('provider'),
  new Attribute('label'),
  new Attribute('isMapped', 'isMapped', 'boolean'),
  new Attribute('isIgnored', 'isIgnored', 'boolean'),
]);
ProviderTicketingMapping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'ticketing', 'ticketing'),
  new Relation(
    Relation.ONE_TO_MANY,
    'providerTicketPriceMapping',
    'providerTicketPriceMappingList'
  ),
]);

export default ProviderTicketingMapping;
