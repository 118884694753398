import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderMappingNotificationClient extends AbstractClient<
  TSMetadata['providerMappingNotification']
> {
  getPathBase(): string {
    return '/v1/provider_mapping_notifications';
  }
}

export default ProviderMappingNotificationClient;
