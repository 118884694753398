import {
  Booking,
  Cart,
  CartItem,
  AvailableSeat,
  Ticket,
  Order,
  Fields,
  assertRelationIsObject,
  assertRelationIsListOfObject,
  BOOKING_ENTITY_TYPE,
} from 'mapado-ticketing-js-sdk';
import { HttpError } from 'rest-client-sdk';
import type { BookingActionTypes } from './BookingReducer';
import TicketingSdkInstance from '../../TicketingSdkInstance';
import { AVAILABLE_SEAT_FIELDS } from '../../actions/fields';
import {
  REQUEST_BOOKING_COLLECTION,
  RECEIVE_BOOKING_COLLECTION,
  RESET_BOOKING,
  SET_BOOKING_STATUS,
} from '../../reducers/types';
import { getLongId } from '../../utils/entity';

const TICKET_FIELDS: Fields = [
  'ticketPriceName',
  'ticketPriceType',
  'facialValue',
  'currency',
  {
    availableSeat: AVAILABLE_SEAT_FIELDS,
  },
  { participant: ['crmId', 'firstname', 'lastname'] },
];

const BOOKING_FIELDS: Fields = [
  '@id',
  'entityId',
  'entityName',
  {
    bookingItemList: [
      'ticketPriceFacialValue',
      'ticketPriceName',
      'currency',
      'seatId',
      'seatLabel',
      { participant: ['firstname', 'lastname', 'email', 'phone', 'crmId'] },
      { customer: ['firstname', 'lastname', 'email', 'phone', 'crmId'] },
    ],
  },
];

export const ORDER_FIELDS_FOR_BOOKING: Fields = [
  '@id',
  'createdAt',
  'originType',
  'status',
  'description',
  'currency',
  'contract',
  'comment',
  'retailValue',
  'nbTickets',
  'nbCoupons',
  {
    orderItemList: [
      {
        ticketList: TICKET_FIELDS,
      },
      {
        ticketPrice: [{ ticketPriceSeatGroupList: ['seatGroup'] }],
      },
    ],
  },
  { customer: ['firstname', 'lastname', 'email', 'phone', 'crmId'] },
  {
    cartItemWithOfferList: ['facialValue', 'facialFeeValue', 'quantity'],
  },
  {
    payment: [
      '@id',
      'customerPaidAmount',
      {
        paymentMethodList: ['name', 'customerPaidAmount', 'valueDate'],
      },
      {
        wallet: ['contract'],
      },
    ],
  },
  {
    ticketList: TICKET_FIELDS,
  },
  { sellingDevice: ['isExternalProvider'] },
];

export function filterCartOnEventDate(
  cart: Cart,
  eventDateId: string | number
): Cart {
  assertRelationIsListOfObject(cart.cartItemList, 'cart.cartItemList');

  return cart.set(
    'cartItemList',
    cart.cartItemList.map((cartItem: CartItem) => {
      assertRelationIsListOfObject(
        cartItem.availableSeatList,
        'cartItem.availableSeatList'
      );

      return cartItem.set(
        'availableSeatList',
        cartItem.availableSeatList.filter(
          (availableSeat: AvailableSeat) =>
            availableSeat.eventDate ===
            getLongId('/v1/event_dates/', eventDateId)
        )
      );
    })
  );
}

export function filterOrderOnEventDate(
  order: Order,
  eventDateId: number | string
): Order {
  assertRelationIsListOfObject(order.ticketList, 'order.ticketList');

  return order.set(
    'ticketList',
    order.ticketList.filter(
      (ticket: Ticket) =>
        ticket.availableSeat !== null &&
        typeof ticket.availableSeat === 'object' &&
        ticket.availableSeat.eventDate ===
          getLongId('/v1/event_dates/', eventDateId)
    )
  );
}

export const CART_FIELDS_FOR_BOOKING: Fields = [
  '@id',
  'reservationName',
  'createdAt',
  'currency',
  'bookTickets',
  'originType',
  'comment',
  'nbTickets',
  'retailValue',
  'type',
  {
    cartItemList: [
      'seatList',
      {
        ticketPrice: [{ ticketPriceSeatGroupList: ['seatGroup'] }],
      },
      {
        availableSeatList: AVAILABLE_SEAT_FIELDS,
      },
      {
        participant: ['firstname', 'lastname'],
      },
    ],
  },
  { customer: ['firstname', 'lastname', 'email', 'crmId'] },
  {
    cartItemWithOfferList: ['facialValue', 'facialFeeValue', 'quantity'],
  },
  { sellingDevice: ['isExternalProvider', 'originType'] },
];

export function resetBooking(): BookingActionTypes {
  return {
    type: RESET_BOOKING,
  };
}

type GetBookingCollectionParam = {
  dispatch: React.Dispatch<BookingActionTypes>;
  eventDateId: string | number;
  contractId: string;
  currentPage: number;
  getFirstPage: boolean;
  itemsPerPage: number;
  search?: string;
};

export function getBookingCollection({
  dispatch,
  eventDateId,
  contractId,
  currentPage,
  getFirstPage,
  itemsPerPage,
  search = '',
}: GetBookingCollectionParam): Promise<void> {
  const page = getFirstPage ? 1 : currentPage + 1;

  dispatch({
    type: REQUEST_BOOKING_COLLECTION,
    page,
    search,
  });

  const fields = BOOKING_FIELDS.slice(0);

  fields.push({
    cart: ['@id', { customer: ['firstname', 'lastname', 'email', 'crmId'] }],
  });

  fields.push({
    order: [
      '@id',
      { customer: ['firstname', 'lastname', 'email', 'phone', 'crmId'] },
    ],
  });

  const params: { [key: string]: string | number } = {
    eventDate: eventDateId,
    itemsPerPage,
    page,
    'order[scanned]': 'ASC',
    'order[name]': 'ASC',
    'order[createdAt]': 'DESC',
    contract: contractId,
    type: [BOOKING_ENTITY_TYPE.RESERVATION, BOOKING_ENTITY_TYPE.ORDER].join(
      ','
    ),
  };

  if (search) {
    params.q = search;
  }

  return TicketingSdkInstance.getSdk()
    .getRepository('booking')
    .findBy(params, fields)
    .then((bookingCollection) => {
      const newMembers = bookingCollection
        .getMembers()
        .map((booking: Booking) => {
          if (booking.order !== null) {
            assertRelationIsObject(booking.order, 'booking.order');

            const updatedOrder = filterOrderOnEventDate(
              booking.order,
              eventDateId
            );

            return booking.set('order', updatedOrder);
          }

          if (booking.cart !== null) {
            assertRelationIsObject(booking.cart, 'booking.cart');

            const updatedCart = filterCartOnEventDate(
              booking.cart,
              eventDateId
            );

            return booking.set('cart', updatedCart);
          }

          return booking;
        });

      return bookingCollection.set('hydra:member', newMembers);
    })
    .then((bookingCollection) =>
      bookingCollection.set(
        'hydra:member',
        bookingCollection.getMembers().filter((booking: Booking) => {
          if (
            booking.order !== null &&
            typeof booking.order === 'object' &&
            booking.order.status === 'refunded_totally'
          ) {
            return false;
          }

          return true;
        })
      )
    )
    .then((bookingCollection) => {
      return dispatch({
        type: RECEIVE_BOOKING_COLLECTION,
        bookingCollection,
        page,
        search,
      });
    })
    .catch((err) => {
      if (err instanceof HttpError) {
        err.baseResponse.json().then((json) => {
          return dispatch({
            type: SET_BOOKING_STATUS,
            status: '',
            error: json['hydra:description'],
          });
        });
      }

      return dispatch({
        type: SET_BOOKING_STATUS,
        status: '',
        error: err?.message,
      });
    });
}
