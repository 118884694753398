import { List, Map } from 'immutable';
import { EntityRelation } from '../entity';
import type AvailableSeat from '../entity/AvailableSeat';
import type Cart from '../entity/Cart';
import type CartItem from '../entity/CartItem';
import type Coupon from '../entity/Coupon';
import type { EventDateOptionStatusType } from '../entity/EventDateOptionStatus';
import type ProviderExportedCart from '../entity/ProviderExportedCart';
import { CART_TYPE, CART_STATUS } from '../types/CartTypes';
import { CUSTOMER_TYPE } from '../types/CustomerTypes';

type ImmutableEntity = { get: (id: '@id') => null | string };
type ObjectEntity = { '@id': null | string };
type IdEntity = string | ImmutableEntity | ObjectEntity;

function getEntityId(entity: IdEntity): null | string {
  if (typeof entity === 'string') {
    return entity;
  }

  if (typeof entity === 'object' && entity !== null) {
    if ('@id' in entity) {
      return entity['@id'];
    }

    return entity.get('@id');
  }

  return null;
}

type NormalizedCustomer = Partial<{
  type: null | CUSTOMER_TYPE;
  crmId: string;
  firstname: string;
  lastname: string;
  email: string;
  birthday: string;
  nationality: string;
  countryOfResidence: string;
  civility: string;
  zipcode: string;
  address: string;
  city: string;
  phone: string;
  additionnalInformation: null | Record<string, unknown>;
  shoppingData: null | Record<string, unknown>;
  gdprInformation: null | Record<string, unknown>;
  client: string;
}>;

function isMap<K, V>(object: unknown): object is Map<K, V> {
  return Map.isMap(object);
}

function normalizeCartCustomer(
  customerParam: IdEntity | Map<string, unknown> | Record<string, unknown>
): null | string | NormalizedCustomer {
  if (!customerParam) {
    return null;
  }

  const customerId = getEntityId(customerParam as IdEntity);
  if (customerId) {
    return customerId;
  }

  const customer = (isMap(customerParam)
    ? customerParam.toJS()
    : customerParam) as NormalizedCustomer;

  const customerJS: NormalizedCustomer = {
    crmId: customer.crmId,
    lastname: customer.lastname,
    firstname: customer.firstname,
    email: customer.email,
  };

  if (customer.type !== null) {
    customerJS.type = customer.type;
  }
  if (customer.birthday !== null) {
    customerJS.birthday = customer.birthday;
  }
  if (customer.nationality !== null) {
    customerJS.nationality = customer.nationality;
  }
  if (customer.countryOfResidence !== null) {
    customerJS.countryOfResidence = customer.countryOfResidence;
  }
  if (customer.civility !== null) {
    customerJS.civility = customer.civility;
  }
  if (customer.zipcode !== null) {
    customerJS.zipcode = customer.zipcode;
  }
  if (customer.address !== null) {
    customerJS.address = customer.address;
  }
  if (customer.city !== null) {
    customerJS.city = customer.city;
  }
  if (customer.phone !== null) {
    customerJS.phone = customer.phone;
  }
  if (customer.additionnalInformation !== null) {
    customerJS.additionnalInformation = customer.additionnalInformation;
  }
  if (customer.shoppingData !== null) {
    customerJS.shoppingData = customer.shoppingData;
  }
  if (customer.gdprInformation !== null) {
    customerJS.gdprInformation = customer.gdprInformation;
  }
  if (customer.client !== null) {
    customerJS.client = customer.client;
  }

  return customerJS;
}

type NormalizedCartItem = {
  '@id': null | string;
  '@type': 'CartItem';
  ticketPrice: string;
  quantity: number;
  groupKey: null | string;
  stockContingent: null | string;
  availableSeatList?: string[];
  facialValue?: number;
  data?: Record<string, unknown>;
  participant?: null | string;
};

export type NormalizedCart = {
  '@id': null | string;
  '@type': 'Cart';
  currency: null | string;
  customer: null | string;
  status: null | CART_STATUS;
  type: null | CART_TYPE;
  option: null | string;
  reservationName: null | string;
  comment: null | string;
  couponCode: null | string;
  couponCodeList: null | List<string>;
  tagList: null | string[];
  eventDateOptionStatusList: null | Array<EventDateOptionStatusType>;
  parent: null | string;
  sellingDevice?: null | string;
  originType?: null | string;
  originId?: null | string;
  cartItemList: null | Array<string | NormalizedCartItem>;
  appliedCouponList: null | List<EntityRelation<Coupon>>;
  createdCouponList: null | List<EntityRelation<Coupon>>;
  providerExportedCartList: null | List<EntityRelation<ProviderExportedCart>>;
};

export default function normalizeCart(cart: Cart): NormalizedCart {
  const normalizedCart: NormalizedCart = {
    '@id': cart.get('@id'),
    '@type': 'Cart',
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    currency: cart.currency,
    /** @ts-expect-error */
    customer: normalizeCartCustomer(cart.customer),
    contact: cart.contact && normalizeCartCustomer(cart.contact),
    status: cart.status,
    type: cart.type,
    option: cart.option ? getEntityId(cart.option) : null,
    reservationName: cart.reservationName,
    comment: cart.comment,
    couponCode: cart.couponCode,
    couponCodeList: cart.couponCodeList,
    tagList: cart.tagList
      ? (cart.tagList
          .map(getEntityId)
          .toArray()
          .filter((a) => a !== null) as string[])
      : null,
    eventDateOptionStatusList: cart.eventDateOptionStatusList
      ? cart.eventDateOptionStatusList
          .map((eventDateOptionStatus) => eventDateOptionStatus.toObject())
          .toArray()
      : null,
    parent: cart.parent ? getEntityId(cart.parent) : null,
    cartItemList:
      cart.cartItemList
        ?.map((cartItem: EntityRelation<CartItem>):
          | string
          | NormalizedCartItem => {
          if (typeof cartItem === 'string') {
            return cartItem;
          }

          const cartItemJS: NormalizedCartItem = {
            '@id': cartItem['@id'],
            '@type': 'CartItem',
            ticketPrice:
              (cartItem.ticketPrice && getEntityId(cartItem.ticketPrice)) || '',
            quantity: cartItem.quantity,
            groupKey: cartItem.groupKey,
            stockContingent: cartItem.stockContingent
              ? getEntityId(cartItem.stockContingent)
              : null,
            participant: cartItem.participant
              ? getEntityId(cartItem.participant)
              : null,
          };

          if (cartItem.availableSeatList) {
            /** @ts-expect-error */
            cartItemJS.availableSeatList = cartItem.availableSeatList
              .map((availableSeat: EntityRelation<AvailableSeat>):
                | null
                | string => {
                if (typeof availableSeat === 'string') {
                  return availableSeat;
                }

                return availableSeat.get('@id');
              })
              .filter((v: null | string) => !!v)
              .toArray();
          }

          /** @ts-expect-error */
          if (cartItem.facialValue > 0 || cartItem.facialValue === 0) {
            /** @ts-expect-error */
            cartItemJS.facialValue = cartItem.facialValue;
          }
          if (cartItem.data !== null) {
            cartItemJS.data = cartItem.data;
          }

          return cartItemJS;
        })
        .toArray() || null,
    appliedCouponList: cart.appliedCouponList,
    createdCouponList: cart.createdCouponList,
    providerExportedCartList: cart.providerExportedCartList,
  };

  if (cart.sellingDevice) {
    normalizedCart.sellingDevice = cart.sellingDevice
      ? getEntityId(cart.sellingDevice)
      : null;
  } else if (cart.originType) {
    normalizedCart.originType = cart.originType;
  }

  if (cart.originId) {
    normalizedCart.originId = cart.originId;
  }
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  return normalizedCart;
}
