import clone from 'clone';
import { List, Map } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TicketingClient from '../client/TicketingClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { ALL_IMAGE_TYPES, Media } from '../utils/mediaType';
import Contract from './Contract';
import EventDate, { EVENT_DATE_AVAILABILITY_STATUS } from './EventDate';
import EventDatePattern from './EventDatePattern';
import Minisite from './Minisite';
import Module from './Module';
import SibilTicketingSpectacle from './Module/Sibil/SibilTicketingSpectacle';
import NetworkEntity from './NetworkEntity';
import OrganizationalUnit from './OrganizationalUnit';
import Season from './Season';
import Tag from './Tag';
import TicketingCategory from './TicketingCategory';
import TicketingSellingDevice from './TicketingSellingDevice';
import Venue from './Venue';
import Wallet from './Wallet';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum TICKETING_AVAILABILITY_STATUS {
  NO_TICKET_PRICE = 'noTicketPrice',
  ON_HOLD = 'onHold',
  ON_SALE = 'onSale',
  OTHER = 'other',
  PAST_OR_CLOSED = 'pastOrClosed',
  SOLD_OUT = 'soldOut',
  SOLD_OUT_ONLINE = 'soldOutOnline',
  SOLD_OUT_NOT_IN_STOCK_CONTINGENT = 'soldOutNotInStockContingent',
}

export const TICKETING_TYPE_DATED_EVENTS = 'dated_events';
export const TICKETING_TYPE_UNDATED_EVENT = 'undated_event';
export const TICKETING_TYPE_OFFER = 'offer';

export type TICKETING_TYPE_LIST =
  | typeof TICKETING_TYPE_DATED_EVENTS
  | typeof TICKETING_TYPE_UNDATED_EVENT
  | typeof TICKETING_TYPE_OFFER;

export type TicketingType = BaseEntity<'Ticketing'> & {
  contract: null | EntityRelation<Contract>;
  activityUuid: null | string;
  slug: null | string;
  city: null | string;
  citySlug: null | string;
  countryCode: null | string;
  activitySlug: null | string;
  title: null | string;
  description: null | string;
  type: null | TICKETING_TYPE_LIST;
  address: null | string;
  place: null | string;
  schedule: null | string;
  sellingDeviceSchedule: null | Map<string, unknown>;
  imageList: null | List<string>;
  mediaList: null | List<Media>;
  timezone: null | string;
  currency: null | string;
  hideFees: null | boolean;
  permanent: null | boolean;
  isOnSale: null | boolean;
  wallet: null | EntityRelation<Wallet>;
  eventDateList: null | List<EntityRelation<EventDate>>;
  eventDatePatternList: null | List<EntityRelation<EventDatePattern>>;
  minisite: null | Minisite;
  minisiteList: null | List<EntityRelation<Minisite>>;
  sellingDeviceData: null | List<EntityRelation<TicketingSellingDevice>>;
  tagList: null | List<EntityRelation<Tag>>;
  availabilityStatus: null | string;
  futureEventDatesAvailabilityStatus?: EVENT_DATE_AVAILABILITY_STATUS;
  customerRequiredFields: null | Map<string, unknown>;
  firstFutureEventDate: null | EntityRelation<EventDate>;
  firstFutureEventDateStartDate: null | string;
  venue: null | EntityRelation<Venue>;
  sibilTicketingSpectacle: null | EntityRelation<SibilTicketingSpectacle>;
  position: null | number;
  templateParameters: null | Map<string, unknown>;
  season: null | EntityRelation<Season>;
  ticketingCategory: null | EntityRelation<TicketingCategory>;
  organizationalUnit: null | EntityRelation<OrganizationalUnit>;
  totalEventDateCount: null | number;
  futureEventDateCount: null | number;
  moduleList: null | List<EntityRelation<Module>>;
  notInStockContingentBookableStock: null | number;
  unlimitedStock: null | boolean;
  contingentBookableStock: null | Map<string, number>;
  color: undefined | string;
};

// those fields are mandatory, if "fields" is given, then their values will be a non-nullable string
export type TicketingNonNullProps =
  | '@id'
  | 'timezone'
  | 'wallet'
  | 'title'
  | 'type'
  | 'contract'
  | 'isOnSale'
  | 'slug'
  | 'position';

class Ticketing extends NetworkEntity<TicketingType>({
  '@id': null,
  '@type': 'Ticketing',
  contract: null,
  activityUuid: null,
  slug: '',
  city: '',
  citySlug: '',
  countryCode: '',
  activitySlug: '',
  title: '',
  description: '',
  type: null,
  address: '',
  place: '',
  schedule: '',
  sellingDeviceSchedule: null,
  imageList: null,
  mediaList: null,
  timezone: '',
  currency: '',
  hideFees: false,
  permanent: false,
  isOnSale: false,
  wallet: null,
  eventDateList: List<EntityRelation<EventDate>>(),
  eventDatePatternList: List<EntityRelation<EventDatePattern>>(),
  minisite: new Minisite(),
  minisiteList: List<EntityRelation<Minisite>>(),
  sellingDeviceData: List<EntityRelation<TicketingSellingDevice>>(),
  tagList: List<EntityRelation<Tag>>(),
  availabilityStatus: '',
  customerRequiredFields: Map<string, unknown>(),
  firstFutureEventDate: null,
  firstFutureEventDateStartDate: '',
  venue: null,
  sibilTicketingSpectacle: null,
  position: null,
  templateParameters: Map<string, unknown>(),
  season: null,
  ticketingCategory: null,
  organizationalUnit: null,
  totalEventDateCount: null,
  futureEventDateCount: null,
  moduleList: List<EntityRelation<Module>>(),
  notInStockContingentBookableStock: null,
  unlimitedStock: null,
  contingentBookableStock: null,
  futureEventDatesAvailabilityStatus: undefined,
  color: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketingType> = { '@id': null, '@type': 'Ticketing' }
  ) {
    const data = clone(val);

    if (
      typeof data.contingentBookableStock === 'object' &&
      data.contingentBookableStock !== null
    ) {
      data.contingentBookableStock = Map(data.contingentBookableStock);
    }

    super(data);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticketings/', '') || '';
  }

  getMainImageMedia(): null | Media {
    if (!this.mediaList) {
      return null;
    }

    const imageMediaList = this.mediaList.filter((m) => {
      const imageType = m.get('imageType');

      return imageType && ALL_IMAGE_TYPES.includes(imageType);
    });

    return imageMediaList.first();
  }
}

Ticketing.classMetadata = new ClassMetadata(
  'ticketing',
  'ticketings',
  /** @ts-expect-error -- method signature are incompatible */
  TicketingClient
);
Ticketing.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('activityUuid'),
  new Attribute('slug'),
  new Attribute('city'),
  new Attribute('citySlug'),
  new Attribute('countryCode'),
  new Attribute('activitySlug'),
  new Attribute('title'),
  new Attribute('description'),
  new Attribute('type'),
  new Attribute('address'),
  new Attribute('place'),
  new Attribute('schedule'),
  new Attribute('sellingDeviceSchedule', 'sellingDeviceSchedule', 'object'),
  new Attribute('imageList', 'imageList', 'array'),
  new Attribute('mediaList', 'mediaList', 'array'),
  new Attribute('timezone'),
  new Attribute('currency'),
  new Attribute('hideFees', 'hideFees', 'boolean'),
  new Attribute('permanent', 'permanent', 'boolean'),
  new Attribute('isOnSale', 'isOnSale', 'boolean'),
  new Attribute('sellingDeviceData', 'sellingDeviceData', 'array'),
  new Attribute('availabilityStatus'),
  new Attribute('futureEventDatesAvailabilityStatus'),
  new Attribute('customerRequiredFields', 'customerRequiredFields', 'object'),
  new Attribute('templateParameters', 'templateParameters', 'object'),
  new Attribute('firstFutureEventDateStartDate'),
  new Attribute('position'),
  new Attribute('totalEventDateCount', 'totalEventDateCount', 'integer'),
  new Attribute('futureEventDateCount', 'futureEventDateCount', 'integer'),
  new Attribute(
    'notInStockContingentBookableStock',
    'notInStockContingentBookableStock',
    'integer'
  ),
  new Attribute('unlimitedStock', 'unlimitedStock', 'boolean'),
  new Attribute('contingentBookableStock', 'contingentBookableStock', 'object'),
  new Attribute('color'),
]);
Ticketing.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'firstFutureEventDate'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
  new Relation(Relation.ONE_TO_MANY, 'eventDate', 'eventDateList'),
  new Relation(Relation.ONE_TO_MANY, 'tag', 'tagList'),
  new Relation(
    Relation.ONE_TO_MANY,
    'eventDatePattern',
    'eventDatePatternList'
  ),
  new Relation(Relation.MANY_TO_ONE, 'minisite', 'minisite'),
  new Relation(Relation.ONE_TO_MANY, 'minisite', 'minisiteList'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contracts'),
  new Relation(Relation.MANY_TO_ONE, 'venue', 'venue'),
  new Relation(
    Relation.ONE_TO_ONE,
    'sibilTicketingSpectacle',
    'sibilTicketingSpectacle'
  ),
  new Relation(Relation.MANY_TO_ONE, 'season', 'season'),
  new Relation(Relation.MANY_TO_ONE, 'ticketingCategory', 'ticketingCategory'),
  new Relation(
    Relation.MANY_TO_ONE,
    'organizationalUnit',
    'organizationalUnit'
  ),
  new Relation(Relation.MANY_TO_MANY, 'module', 'moduleList'),
]);

export default Ticketing;
