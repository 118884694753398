import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderTicketPriceMappingClient from '../../../client/ProviderTicketPriceMappingClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import NetworkEntity from '../../NetworkEntity';
import TicketPrice from '../../TicketPrice';
import { ProviderTicketMappingType } from './ProviderTicketMappingType';

export type ProviderTicketPriceMappingType = BaseEntity<'ProviderTicketPriceMapping'> &
  ProviderTicketMappingType & {
    ticketPrice?: EntityRelation<TicketPrice> | null;
    eventName?: string;
  };

class ProviderTicketPriceMapping extends NetworkEntity<ProviderTicketPriceMappingType>(
  {
    '@id': null,
    '@type': 'ProviderTicketPriceMapping',
    contract: undefined,
    provider: undefined,
    ticketPrice: undefined,
    label: undefined,
    isMapped: undefined,
    eventName: undefined,
    isIgnored: undefined,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderTicketPriceMappingType> = {
      '@id': null,
      '@type': 'ProviderTicketPriceMapping',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_ticket_price_mappings/', '') || ''
    );
  }
}

ProviderTicketPriceMapping.classMetadata = new ClassMetadata(
  'providerTicketPriceMapping',
  'provider_ticket_price_mappings',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderTicketPriceMappingClient
);
ProviderTicketPriceMapping.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('provider'),
  new Attribute('label'),
  new Attribute('isMapped', 'isMapped', 'boolean'),
  new Attribute('eventName'),
  new Attribute('isIgnored', 'isIgnored', 'boolean'),
]);
ProviderTicketPriceMapping.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'contract', 'contract'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPrice', 'ticketPrice'),
]);

export default ProviderTicketPriceMapping;
