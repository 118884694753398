import { fromJS, Map, MapOf } from 'immutable';
import URI from 'urijs';
import { Iri } from '../entity';
import Cart, { CartType } from '../entity/Cart';
import {
  PROVIDER_EXPORTED_CART,
  ProviderPassCultureDefaultData,
} from '../entity/ProviderExportedCart';
import { TSMetadata } from '../mapping';
import FormDataClient from './FormDataClient';

class CartClient extends FormDataClient<TSMetadata['cart']> {
  getPathBase(pathParameters: Record<string, unknown> = {}): string {
    if (pathParameters.simulate === true) {
      return `${super.getPathBase(pathParameters)}/simulate`;
    }

    return super.getPathBase(pathParameters);
  }

  simulate(
    entity: Cart,
    queryParam: Record<string, unknown>
  ): Promise<CartType> {
    return this.create(entity, queryParam, { simulate: true });
  }

  getPdfQuotation(
    id: string | number,
    name: string,
    vatNumber: string,
    address: string,
    customerAdditionalInfo: string,
    quotationType: string
  ): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/quotation.pdf`);
    url.addSearch({
      name,
      vat: vatNumber,
      address,
      customerAdditionalInfo,
      quotationType,
    });
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  notifyOptions(
    filters: Map<string, string | undefined>,
    approvedMessage: string,
    rejectedMessage: string
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/notify-options`);

    const body = {
      filters: filters.toJS(),
      approvedMessage,
      rejectedMessage,
    };

    return this.authorizedFetch(uri, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  async getProviderExportedCartData(
    id: Iri<'Cart'>,
    providerName: PROVIDER_EXPORTED_CART.PASS_CULTURE
  ): Promise<MapOf<ProviderPassCultureDefaultData>>;

  async getProviderExportedCartData(
    id: Iri<'Cart'>,
    providerName: PROVIDER_EXPORTED_CART
  ): Promise<Map<string, unknown>> {
    const url = new URI(`/${id}/provider_exported_cart/${providerName}`);

    const response = await this.authorizedFetch(url, {
      method: 'GET',
    });

    return fromJS(await response.json());
  }

  hideByFilter(
    filters: Map<string, string | undefined | boolean>
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/hide`);

    const body = {
      filters: filters.toJS(),
    };

    return this.authorizedFetch(uri, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
}

export default CartClient;
