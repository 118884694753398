import clone from 'clone';
import { List, Map, Record } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderTicketImportedFileClient from '../../../client/Import/ProviderTicketImportedFileClient';
import { mapEntityRelationShips } from '../../../entityFactory';
import { parseDate } from '../../../utils/date';
import Contract from '../../Contract';
import EventDate from '../../EventDate';
import ScanTrack from '../../ScanTrack';
import Ticket from '../../Ticket';
import Context from './Context';
import ImportedFile from './ImportedFile';
import Mapping from './Mapping';
import Sheet from './Sheet';
import { IMPORT_STATUS } from './statuses';

export enum STRATEGY {
  FULL = 'full',
  CONTROL_ONLY = 'control_only',
}

export enum STRATEGY_MODE {
  SPLIT = 'split',
  GROUP = 'group',
  NONE = 'none',
}

export type ProviderTicketImportedFileType = BaseEntity<'ProviderTicketImportedFile'> & {
  importedAt: null | Moment;
  importToken: null | string;
  sheet: null | Sheet;
  mapping: null | Mapping;
  importStatus: null | IMPORT_STATUS;
  context: null | Context;
  importedFile: null | ImportedFile;
  eventDate: null | EntityRelation<EventDate>;
  scanTrack: null | EntityRelation<ScanTrack>;
  contract: null | EntityRelation<Contract>;
  externalProvider: null | string;
  ticketList: null | List<EntityRelation<Ticket>>;
  totalTicketsImported: null | number;
  canBeDeleted: null | boolean;
  canBeRetried: null | boolean;
  canDownloadErrorReport: null | boolean;
  strategy: undefined | STRATEGY;
  strategyMode: undefined | STRATEGY_MODE;
  allowedProviders: undefined | List<string>;
};

class ProviderTicketImportedFile extends Record<
  PartialEntity<ProviderTicketImportedFileType>
>({
  '@id': null,
  '@type': 'ProviderTicketImportedFile',
  importedAt: null,
  importToken: null,
  sheet: null,
  mapping: null,
  importStatus: null,
  context: null,
  importedFile: null,
  externalProvider: null,
  eventDate: null,
  scanTrack: null,
  ticketList: null,
  totalTicketsImported: null,
  canBeDeleted: null,
  canBeRetried: null,
  canDownloadErrorReport: null,
  strategy: undefined,
  strategyMode: undefined,
  allowedProviders: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderTicketImportedFileType> = {
      '@id': null,
      '@type': 'ProviderTicketImportedFile',
    }
  ) {
    const data = clone(val);

    if (data.context) {
      data.context = new Context(data.context);
    }

    if (data.sheet) {
      const { columns, values, distinctValues, ...rest } = data.sheet;
      data.sheet = new Sheet({
        columns: Map(columns),
        values: List(values.map((map) => Map(map))),
        distinctValues: Map(distinctValues),
        ...rest,
      });
    }

    if (data.mapping) {
      const { columns, statuses } = data.mapping;
      data.mapping = new Mapping({
        columns: Map(columns),
        statuses: Map(statuses),
      });
    }

    if (data.importedFile) {
      data.importedFile = new ImportedFile(data.importedFile);
    }

    data.importedAt = parseDate(data.importedAt, null);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_ticket_imported_files/', '') || ''
    );
  }
}

ProviderTicketImportedFile.classMetadata = new ClassMetadata(
  'providerTicketImportedFile',
  'provider_ticket_imported_files',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderTicketImportedFileClient
);

ProviderTicketImportedFile.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('importedAt', 'importedAt', 'datetime'),
  new Attribute('importToken'),
  new Attribute('importStatus'),
  new Attribute('externalProvider'),
  new Attribute('sheet', 'sheet', 'object'),
  new Attribute('context', 'context', 'object'),
  new Attribute('mapping', 'mapping', 'object'),
  new Attribute('totalTicketsImported', 'totalTicketsImported', 'integer'),
  new Attribute('canBeDeleted', 'canBeDeleted', 'boolean'),
  new Attribute('canBeRetried', 'canBeRetried', 'boolean'),
  new Attribute('canDownloadErrorReport', 'canDownloadErrorReport', 'boolean'),
  new Attribute('allowedProviders', 'allowedProviders', 'array'),
  new Attribute('strategy', 'strategy', 'string'),
  new Attribute('strategyMode', 'strategyMode', 'string'),
]);

ProviderTicketImportedFile.classMetadata.setRelationList([
  new Relation(Relation.ONE_TO_MANY, 'ticket', 'ticketList'),
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'scanTrack', 'scanTrack'),
]);

export default ProviderTicketImportedFile;
