import URI from 'urijs';
import { TSMetadata } from '../mapping';
import AbstractClient from './AbstractClient';

class ProviderAutoImportedTicketClient extends AbstractClient<
  TSMetadata['providerAutoImportedTicket']
> {
  getPathBase(): string {
    return '/v1/provider_auto_imported_tickets';
  }

  processHandle(provider: string, contractIri: string): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/process/handle`);

    const body = {
      contract: contractIri,
      provider,
    };

    return this.authorizedFetch(uri, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
}

export default ProviderAutoImportedTicketClient;
