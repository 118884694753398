import { List, Map, MapOf } from 'immutable';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import Contract from './Contract';
import Module from './Module';
import NetworkEntity from './NetworkEntity';
import SellingDevice from './SellingDevice';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export enum MINISITE_INTEGRATIONS {
  COVOITURAGE_SIMPLE = 'covoiturage-simple',
}

export type MinisiteType = BaseEntity<'Minisite'> & {
  slug: null | string;
  domain: null | string;
  name: null | string;
  companyName: null | string;
  sellingDevice: null | EntityRelation<SellingDevice>;
  mainContract: null | EntityRelation<Contract>;
  backgroundUrl: null | string;
  logo: null | string;
  settings: null | Map<string, unknown>;
  moduleList: null | List<EntityRelation<Module>>;
  isDemoMode: null | boolean;
  termsOfSale: null | string;
  isMultiContract: null | boolean;
  isLoginRequired: null | boolean;
  integrations: null | MapOf<{ 'covoiturage-simple': MapOf<{ slug: string }> }>;
};

class Minisite extends NetworkEntity<MinisiteType>({
  '@id': null,
  '@type': 'Minisite',
  slug: null,
  domain: null,
  name: null,
  companyName: null,
  sellingDevice: null,
  mainContract: null,
  backgroundUrl: null,
  logo: null,
  settings: null,
  moduleList: List<Module>(),
  isDemoMode: null,
  termsOfSale: null,
  isMultiContract: null,
  isLoginRequired: null,
  integrations: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<MinisiteType> = {
      '@id': null,
      '@type': 'Minisite',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/minisites/', '') || '';
  }
}

Minisite.classMetadata = new ClassMetadata(
  'minisite',
  'minisites',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);
Minisite.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('slug'),
  new Attribute('domain'),
  new Attribute('name'),
  new Attribute('companyName'),
  new Attribute('backgroundUrl'),
  new Attribute('logo'),
  new Attribute('settings', 'settings', 'object'),
  new Attribute('isDemoMode', 'isDemoMode', 'boolean'),
  new Attribute('termsOfSale'),
  new Attribute('isMultiContract', 'isMultiContract', 'boolean'),
  new Attribute('isLoginRequired', 'isLoginRequired', 'boolean'),
  new Attribute('integrations', 'integrations', 'object'),
]);

Minisite.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'sellingDevice', 'sellingDevice'),
  new Relation(Relation.MANY_TO_ONE, 'contract', 'mainContract'),
  new Relation(Relation.ONE_TO_MANY, 'module', 'moduleList'),
]);

export default Minisite;
