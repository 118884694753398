import { Attribute, ClassMetadata } from 'rest-client-sdk';
import { BaseEntity, PartialEntity } from '../..';
import ProviderAutoImportedTicketClient from '../../../client/ProviderAutoImportedTicketClient';
import { mapEntityRelationShips } from '../../../entityFactory';
import NetworkEntity from '../../NetworkEntity';

export type ProviderAutoImportedTicketType = BaseEntity<'ProviderAutoImportedTicket'> & {
  // Use branded type to avoid error on EntityFactory.ts
  // See https://www.learningtypescript.com/articles/branded-types
  __brand: 'ProviderAutoImportedTicket';
};

class ProviderAutoImportedTicket extends NetworkEntity<ProviderAutoImportedTicketType>(
  {
    '@id': null,
    '@type': 'ProviderAutoImportedTicket',
    __brand: 'ProviderAutoImportedTicket',
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderAutoImportedTicketType> = {
      '@id': null,
      '@type': 'ProviderAutoImportedTicket',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }
}

ProviderAutoImportedTicket.classMetadata = new ClassMetadata(
  'providerAutoImportedTicket',
  'provider_auto_imported_tickets',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderAutoImportedTicketClient
);

ProviderAutoImportedTicket.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
]);

export default ProviderAutoImportedTicket;
