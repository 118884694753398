import { Map, MapOf, fromJS } from 'immutable';
import moment from 'moment';
import type { PartialEntity } from '../entity';
// eslint-disable-next-line import/no-duplicates
import type { CartType } from '../entity/Cart';
// eslint-disable-next-line import/no-duplicates
import type Cart from '../entity/Cart';
// eslint-disable-next-line import/no-duplicates
import type Customer from '../entity/Customer';
// eslint-disable-next-line import/no-duplicates
import type { CustomerType } from '../entity/Customer';
// eslint-disable-next-line import/no-duplicates
import type { OrderType } from '../entity/Order';
// eslint-disable-next-line import/no-duplicates
import type Order from '../entity/Order';
import {
  assertRelationIsNullOrObject,
  assertRelationIsString,
} from '../errors/errorFactory';
import {
  DIFFUSION_LIST_KEY,
  FIELD_PREFIX_SLUG,
} from '../types/CustomerFieldContractTypes';
import {
  CUSTOMER_TYPE,
  CustomerFormValuesType,
  ShoppingDataType,
} from '../types/CustomerTypes';
import {
  FormCustomerField,
  FormData,
  InitialCustomerValuesFormDataType,
} from '../types/FormDataType';

export function getCustomer(customer: null | Customer): null | Customer {
  if (!customer) {
    return null;
  }
  if (!customer.type) {
    return null; // ugly hack to handle the fact that Cart class create a new Customer() in its default values
  }
  return customer;
}

export function getOrganization(
  data: PartialEntity<CartType | OrderType>
): null | Customer {
  const customer = getCustomer(data.customer as Customer | null);

  return customer?.type === CUSTOMER_TYPE.ORGANIZATION ? customer : null;
}

export function getContact(
  data: PartialEntity<CartType | OrderType>
): null | Customer {
  const contact = data.contact as Customer | null;

  if (contact) {
    return contact;
  }

  const customer = getCustomer(data.customer as Customer | null);

  return customer?.type === CUSTOMER_TYPE.CONTACT ? customer : null;
}

export function getFormData(
  data: PartialEntity<CartType | OrderType>
): MapOf<FormData> | null {
  const customerRequiredFields = (data.customerRequiredFields as unknown) as
    | FormCustomerField[]
    | null;

  // Retrieve the entity organization if exist
  const organization = getOrganization(data);
  const organizationInitialValues:
    | InitialCustomerValuesFormDataType
    | object = {
    ...organization?.additionnalInformation,
    ...organization?.diffusionList,
    ...organization,
  };

  // Retrieve the entity contact if exist
  const contact = getContact(data);
  const contactInitialValues: InitialCustomerValuesFormDataType | object = {
    ...contact?.additionnalInformation,
    ...contact?.diffusionList,
    ...contact,
  };

  // Retrieve the entity customer if exist
  // Used for shoppingData only
  const customer = getCustomer(data.customer as Customer | null);
  const customerInitialValues = {
    ...customer?.shoppingData,
  };

  // Parse all entity customerRequiredFields to find fields related to organization, contact or shopping data
  const formData = customerRequiredFields?.reduce((acc, { slug }) => {
    // add field slugs related to organization as formData keys
    // add entity organizationInitialValues as formData values
    if (slug?.startsWith(FIELD_PREFIX_SLUG.ORGANIZATION)) {
      const isDiffusionList = slug?.startsWith(
        FIELD_PREFIX_SLUG.ORGANIZATION_DIFFUSION_LIST
      );
      const initialValueKey = isDiffusionList
        ? slug.substring(FIELD_PREFIX_SLUG.ORGANIZATION_DIFFUSION_LIST.length)
        : slug.substring(FIELD_PREFIX_SLUG.ORGANIZATION.length);

      return {
        ...acc,
        // @ts-expect-error type 'string' can't be used to index type
        [slug]: organizationInitialValues[initialValueKey],
      };
    }

    // add field slugs related to contact as formData keys
    // add entity contactInitialValues as formData values
    if (slug?.startsWith(FIELD_PREFIX_SLUG.CONTACT)) {
      const isDiffusionList = slug?.startsWith(
        FIELD_PREFIX_SLUG.CONTACT_DIFFUSION_LIST
      );
      const initialValueKey = isDiffusionList
        ? slug.substring(FIELD_PREFIX_SLUG.CONTACT_DIFFUSION_LIST.length)
        : slug.substring(FIELD_PREFIX_SLUG.CONTACT.length);

      return {
        ...acc,
        // @ts-expect-error type 'string' can't be used to index type
        [slug]: contactInitialValues[initialValueKey],
      };
    }

    // add field slugs related to shopping data as formData keys
    // add customerInitialValues as formData values
    if (slug?.startsWith(FIELD_PREFIX_SLUG.SHOPPING)) {
      const customerKey = slug.substring(FIELD_PREFIX_SLUG.SHOPPING.length);

      return {
        ...acc,
        // @ts-expect-error type 'string' can't be used to index type
        [slug]: customerInitialValues[customerKey],
      };
    }

    return acc;
  }, {});

  return formData ? Map(formData) : null;
}

export function getParticipantFormData(
  participant: Customer | null | undefined,
  participantRequiredFields: FormCustomerField[] | null
): MapOf<FormData> | null {
  const participantInitialValues = {
    ...participant,
    ...participant?.additionnalInformation,
    ...participant?.diffusionList,
    ...participant?.shoppingData,
  };

  const participantFormData = participantRequiredFields?.reduce(
    (acc, { slug }) => {
      assertRelationIsString(slug);

      const customerKey = Object.values(FIELD_PREFIX_SLUG).reduce(
        (currentSlug, prefix) =>
          currentSlug.startsWith(prefix)
            ? currentSlug.substring(prefix.length)
            : currentSlug,
        slug
      ) as keyof CustomerType;

      return {
        ...acc,
        [slug]: participantInitialValues[customerKey],
      };
    },
    {}
  );
  return participantFormData ? Map(participantFormData) : null;
}

export function getCustomerFormValues(
  formData: FormData,
  slugType: FIELD_PREFIX_SLUG,
  customerKeys: string[]
): CustomerFormValuesType | null {
  const formDataKeys = Object.keys(formData).filter((key) =>
    key.startsWith(slugType)
  );

  if (!formDataKeys.length) {
    // if formData have no matching keys
    return null;
  }

  const customerFormValues = formDataKeys.reduce<CustomerFormValuesType>(
    (acc, formKey) => {
      const key = formKey.substring(slugType.length);
      const value = formData[formKey as keyof FormData];

      if (key.startsWith(DIFFUSION_LIST_KEY)) {
        const diffusionListKey = key.substring(DIFFUSION_LIST_KEY.length);
        // means it's infos related to diffusionList
        return {
          ...acc,
          diffusionList: { ...acc.diffusionList, [diffusionListKey]: value },
        };
      }

      if (customerKeys.includes(key)) {
        // means it's some basic customer infos
        return { ...acc, [key]: value };
      }

      if (key === 'bornAt') {
        // very ugly hack to handle same value with different names depending on multiple APIs
        // crm handle fields 'bornAt' but ticketing customer has birthday prop
        // we keep it in additionnalInformation anyway because crm field handle bornAt and not birthday
        return {
          ...acc,
          birthday: value ? moment(value as string).format('YYYY-MM-DD') : null,
          additionnalInformation: {
            ...acc.additionnalInformation,
            [key]: value,
          },
        };
      }

      // put every others custom form values in additionnalInformation
      return {
        ...acc,
        additionnalInformation: {
          ...acc.additionnalInformation,
          [key]: value,
        },
      };
    },
    {}
  );

  const hasValues =
    Object.values(customerFormValues).filter(Boolean).length > 0;

  return hasValues ? customerFormValues : null;
}

export function getShoppingValues(formData: FormData): ShoppingDataType | null {
  const shoppingKeys = Object.keys(formData).filter((key) =>
    key.startsWith(FIELD_PREFIX_SLUG.SHOPPING)
  );

  if (!shoppingKeys.length) {
    // if formData have no shopping keys
    return null;
  }

  const shoppingData = shoppingKeys.reduce<ShoppingDataType>(
    (acc, key) => ({
      ...acc,
      [key.substring(FIELD_PREFIX_SLUG.SHOPPING.length)]: formData[
        key as keyof FormData
      ],
    }),
    {}
  );

  return Object.values(shoppingData).filter(Boolean).length > 0
    ? shoppingData
    : null;
}

export function mergeCustomerFormValues(
  customer: Customer,
  customerValues: CustomerFormValuesType | null,
  shoppingValues: ShoppingDataType | null
): Customer | null {
  if (!customerValues && !shoppingValues) {
    return null;
  }

  const values = {
    ...customerValues,
    diffusionList: {
      ...customerValues?.diffusionList,
    },
    additionnalInformation: {
      ...customerValues?.additionnalInformation,
    },
    shoppingData: {
      ...customerValues?.shoppingData,
      ...shoppingValues,
    },
  };

  return customer.merge(fromJS(values)) ?? null;
}

export function getNewCustomersIds(
  entity: Cart | Order,
  newCustomerList: Customer[]
): {
  customerId: `/v1/customers/${number}` | null;
  contactId: `/v1/customers/${number}` | null;
} {
  const newOrganization = newCustomerList.find(
    (customer) => customer.type === CUSTOMER_TYPE.ORGANIZATION
  );
  const newContact = newCustomerList.find(
    (customer) => customer.type === CUSTOMER_TYPE.CONTACT
  );
  const { customer, contact } = entity;
  assertRelationIsNullOrObject(customer);
  assertRelationIsNullOrObject(contact);

  if (newOrganization && customer?.type === CUSTOMER_TYPE.CONTACT) {
    // if an organization has been created it must become the cart customer
    // if exist, the current contact customer becomes cart.contact
    // if there were no customer, we set the possibly new contact as cart.contact
    return {
      customerId: newOrganization.get('@id') ?? null,
      contactId: (customer ?? newContact)?.get('@id') ?? null,
    };
  }

  if (newOrganization || customer?.type === CUSTOMER_TYPE.ORGANIZATION) {
    // if an organization has been created it must become the cart customer
    // if no new organization, the organization that was already there stay
    // and set the new or existing contact as cart.contact
    return {
      customerId: (newOrganization ?? customer)?.get('@id') ?? null,
      contactId: (newContact ?? contact)?.get('@id') ?? null,
    };
  }

  // otherwise, created contact becomes the cart customer
  return { customerId: newContact?.get('@id') ?? null, contactId: null };
}
